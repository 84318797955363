:root {
  --primary-color: #6eb323;
  --primary-hover-color: #11462e;
  --primary-color-disabled: #a5c96f;
  --text-color: #171717;
  --background-color: #ffffff;
  --input-background-color: #f9f9f9;
  --input-border-color: #6eb323;
  --input-focus-border-color: #11462e;
  --table-border-color: #dddddd;
  --table-stripe-color-even: #f2f2f2;
  --table-stripe-color-odd: #ffffff;
  --table-stripe-color-alarm: #ff0f0f4a;
  --box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

/* General Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
  padding: 10px;
}

/* Input Fields */
input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="tel"],
select,
textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  border: 1px solid var(--input-border-color);
  border-radius: 4px;
  font-size: 16px;
  background-color: var(--input-background-color);
  color: var(--text-color);
  height: auto;
  line-height: 1.6;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
  border-color: var(--input-focus-border-color);
  box-shadow: var(--box-shadow);
}

/* Ensure consistent height for select on iOS */
select {
  height: 48px;
  line-height: normal;
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
  padding-right: 36px;
  background: var(--input-background-color)
    url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="%23444444"><path d="M5.516 6.547a.714.714 0 0 1 1.03 0L10 10l3.453-3.453a.714.714 0 0 1 1.03 1.03l-4 4a.714.714 0 0 1-1.03 0l-4-4a.714.714 0 0 1 0-1.03z"/></svg>')
    no-repeat right 10px center;
}

input:disabled,
select:disabled,
textarea:disabled {
  background-color: var(--primary-color-disabled);
  cursor: not-allowed;
}

/* Buttons and Submit/Action Inputs */
button,
input[type="submit"],
input[type="button"] {
  width: 100%;
  padding: 12px;
  margin: 6px 0px;
  background-color: var(--primary-color);
  border: none;
  border-radius: 4px;
  color: var(--background-color);
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover,
input[type="submit"]:hover,
input[type="button"]:hover {
  background-color: var(--primary-hover-color);
}

button:disabled,
input[type="submit"]:disabled,
input[type="button"]:disabled {
  background-color: var(--primary-color-disabled);
  cursor: not-allowed;
}

/* Tables */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th {
  padding: 12px;
  text-align: left;
  background-color: var(--primary-hover-color);
  color: var(--background-color);
  border-bottom: 1px solid var(--table-border-color);
}

td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid var(--table-border-color);
}

td:last-child {
  display: flex;
  gap: 5px;
  justify-content: flex-start;
}

/* Striped Rows */
tbody tr:nth-child(even) {
  background-color: var(--table-stripe-color-even);
}

tbody tr.alarm:nth-child(even) {
  background: repeating-linear-gradient(
    45deg,
    var(--table-stripe-color-even),
    var(--table-stripe-color-even) 10px,
    var(--table-stripe-color-alarm) 10px,
    var(--table-stripe-color-alarm) 20px
  );
}

tbody tr:nth-child(odd) {
  background-color: var(--table-stripe-color-odd);
}

tbody tr.alarm:nth-child(odd) {
  background: repeating-linear-gradient(
    45deg,
    var(--table-stripe-color-odd),
    var(--table-stripe-color-odd) 10px,
    var(--table-stripe-color-alarm) 10px,
    var(--table-stripe-color-alarm) 20px
  );
}

/* Mobile-Friendly Design */
@media only screen and (max-width: 600px) {
  body {
    padding: 5px;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="number"],
  input[type="tel"],
  select,
  textarea,
  button,
  input[type="submit"],
  input[type="button"] {
    padding: 10px;
    font-size: 14px;
  }

  th,
  td {
    padding: 10px;
    font-size: 14px;
  }
}

/* High Visibility for Sunlight */
body,
input,
button,
select,
textarea {
  background-color: var(--background-color);
  color: var(--text-color);
}

button,
input[type="submit"],
input[type="button"] {
  background-color: var(--primary-color);
  color: var(--background-color);
}

button:hover,
input[type="submit"]:hover,
input[type="button"]:hover {
  background-color: var(--primary-hover-color);
  color: var(--background-color);
}

/* Style for icon-only action buttons */
.icon-button {
  width: 40px; /* Allow the button to size based on content */
  height: 40px; /* Set height to keep it square */
  padding: 5px; /* Add some padding around the icon */
  margin: 0 6px;
  display: inline-flex; /* Align icon centrally */
  justify-content: center;
  align-items: center;
  border-radius: 4px; /* Optional: add a border-radius */
  background-color: var(--primary-color); /* Use primary color */
  color: white; /* Icon color */
}

/* Adjust hover/focus state for better feedback */
.icon-button:hover,
.icon-button:focus {
  background-color: var(
    --primary-hover-color
  ); /* Slightly different on hover */
  outline: none; /* Remove default browser outline */
}

.icon-button:disabled {
  background-color: var(--primary-color-disabled);
  outline: none; /* Remove default browser outline */
}

/* Make only tables with class 'responsive' responsive on mobile */
@media only screen and (max-width: 600px) {
  table.responsive,
  table.responsive thead,
  table.responsive tbody,
  table.responsive th,
  table.responsive td,
  table.responsive tr {
    display: block;
  }

  /* Hide table headers for responsive tables */
  table.responsive thead {
    display: none;
  }

  /* Style each row as a block in responsive tables */
  table.responsive tr {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--table-border-color);
    padding: 10px;
    background-color: var(--background-color);
    overscroll-behavior-y: none;
  }

  /* Alternating row background colors (zebra striping) for vertical layout */
  table.responsive tr:nth-child(even) {
    background-color: var(
      --table-stripe-color-even
    ); /* Use existing even stripe color */
  }

  table.responsive tr:nth-child(odd) {
    background-color: var(
      --table-stripe-color-odd
    ); /* Use existing odd stripe color */
  }

  /* Display headings as a left-aligned label before each data point in responsive tables */
  table.responsive td {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    border-bottom: 1px solid var(--table-border-color);
    position: relative;
    text-align: left;
  }

  /* Add data-label attribute for headers in responsive tables */
  table.responsive td::before {
    content: attr(data-label); /* Pull the value of data-label */
    font-weight: bold;
    text-transform: uppercase;
    color: var(--text-color);
    flex: 1 1 auto;
    padding-right: 10px;
  }
}

/* Style for the ul element to use grid layout */
.recipients-list {
  list-style-type: none; /* Remove bullet points */
  padding: 0;
  margin: 0;
}

/* Style for each li to display grid with two columns: name and buttons */
.recipients-list li {
  display: grid;
  grid-template-columns: auto 1fr; /* Name takes most space, buttons take minimal */
  align-items: center; /* Vertically align content */
  padding: 8px 0; /* Add some padding for spacing */
  grid-gap: 10px;
}

/* Limit the max width of the name to create balance */
.recipients-list li > span {
  width: 200px; /* Set a max width for the name */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowed text */
  text-overflow: ellipsis; /* Add '...' for overflowed text */
}

/* Add space between the name and the first button */
.recipients-list li > button {
  margin-left: 10px;
}
