.Root {
  text-align: center;
}

.Root-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Root-logo {
    animation: Root-logo-spin infinite 20s linear;
  }
}

.Root-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Root-link {
  color: #61dafb;
}

@keyframes Root-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Container */
.nav-container {
  position: relative;
  top: 0;
  left: 0;
  width: 150px;
  background-color: #ffffff00;
  padding: 20px;
  z-index: 1; /* Ensure the container is above the content */
  height: 15px; /* Adjust this value to match the height of your nav */
}

/* Hamburger Menu */
.hamburger-menu {
  display: block;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px; /* Adjust this value to position the hamburger icon */
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 5px 0;
  transition: 0.4s;
}

/* Navigation Menu */
.nav-menu {
  position: absolute;
  top: 45px; /* Position the menu below the hamburger icon when closed */
  left: 0;
  width: 100%;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: none;
  list-style: none;
  margin: 0;
}

.nav-menu.show {
  display: block;
  top: 45px; /* Adjust the top position when the menu is open */
}

.nav-menu li {
  transition: background-color 0.3s ease;
}

.nav-menu li:hover {
  background-color: #f0f0f0; /* Change this to your desired hover color */
}

.nav-menu li > a {
  text-decoration: none;
  color: #333;
  display: block;
  padding: 8px 12px;
}

.nav-menu li > button {
  text-decoration: none;
  color: #333;
  display: block;
  padding: 8px 12px;
  border: none;
  background-color: inherit;
  font: inherit;
  margin: inherit;
}

/* Hamburger Menu Animation */
.change .bar:nth-child(1) {
  transform: translate(0, 8px) rotate(45deg);
}

.change .bar:nth-child(2) {
  opacity: 0;
}

.change .bar:nth-child(3) {
  transform: translate(0, -8px) rotate(-45deg);
}

.menu-divider {
  width: 170px;
  height: 2px;
  background-color: #ccc;
  margin: 10px 10px;
}
